<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                        <v-card>
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Filter results</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="card1 = !card1">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <!-- datumska omejitev -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Date limit (from - to)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- začetni datum -->
                                            <v-menu
                                                ref="start_date_menu"
                                                :close-on-content-click="false"
                                                v-model="start_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_start_date_formatted"
                                                        label="Date from"
                                                        prepend-icon="mdi-calendar-today"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- končni datum -->
                                            <v-menu
                                                ref="end_date_menu"
                                                :close-on-content-click="false"
                                                v-model="end_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_end_date_formatted"
                                                        label="Date to"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- časovna omejitev -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Time limit (from - to)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <v-menu
                                                ref="start_time_menu"
                                                v-model="start_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="start_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="start_time"
                                                    label="Start time"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="start_time_menu"
                                                v-model="start_time"
                                                
                                                @click:minute="$refs.start_time_menu.save(start_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                                <v-menu
                                                ref="end_time_menu"
                                                v-model="end_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="end_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="end_time"
                                                    label="End time"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="end_time_menu"
                                                v-model="end_time"
                                                
                                                @click:minute="$refs.end_time_menu.save(end_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- sales locations -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Sales location</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_sales_locations"
                                            :items="salesLocations"
                                            label="Select sales locations"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="No data to display"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- cashiers -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Cashiers</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_cashiers"
                                            :items="cashiers"
                                            label="Select cashiers"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="No data to display"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- payment method-->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Payment method</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_payment_methods"
                                            :items="paymentMethods"
                                            label="Select payment method"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-bookmark-multiple"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="No data to display"

                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                

                                <!-- ZOI -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>ZKI</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="zoi"
                                            label="Enter ZKI number"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearZki"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- EOR -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>JIR</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="eor"
                                            label="Enter JIR number"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearJir"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>  

                                <!-- INVOICE NR. -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Invoice number</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="invoiceNr"
                                            label="Enter invoice number"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearInvoiceNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Transaction ID</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="transactionId"
                                            label="Enter transaction ID"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearTransactionId"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Later fiscalization</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults()" class="ti" v-model="laterFiscalization" :label="`Display only invoices that went to fiscalization later: ${laterFiscalizationOnlyLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-divider v-show="card1"></v-divider>
                            <span v-if="card1">
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn   @click="search()" color="secondary" dark  block outlined style=""><v-icon left>mdi-magnify</v-icon>Search</v-btn><br>
                                <v-btn   @click="exportData()" color="secondary" dark  block outlined style=""><v-icon left>mdi-export</v-icon>Export&nbsp;</v-btn>
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-btn  @click="search()" color="secondary" dark text><v-icon left>mdi-magnify</v-icon>Search</v-btn>
                                    <v-btn  @click="exportData()" color="secondary" dark text><v-icon left>mdi-export</v-icon>Export&nbsp;</v-btn>
                                </v-card-text>
                            </span>
                        </v-card>
                </v-flex>
            </v-layout>
            
            <!--<div style="min-height:15px;"></div>-->

            <!--<widgets></widgets>-->

            <!-- invoice data table -->

            <br>
            <invoices-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></invoices-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            dataTableColumnsMenuId="toggle-invoices-list-columns"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, getAppSettingsFromLocalStorage } from '@/helpers/utilities'
import { isMobileDevice } from '@/helpers/utilities.js'
const InvoicesDataTable = () => import('@/components/InvoicesDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
import { addExportToQueue } from '@/helpers/services.js'
const Widgets = () => import('@/components/Widgets.vue')

export default {
    components:{
        InvoicesDataTable,
        DataTableToggleColumns,
        //Widgets
    },

    data: () => ({
        card1: true,
        card2: true,

        start_time:null,
        end_time: null,

        selected_devices: [],


        restriction_start_year: null,
        restriction_end_year: null,

        restriction_start_date: null,
        restriction_end_date:null,
        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,

        start_time_menu:false,
        end_time_menu:false,

        zoi: null,
        eor: null,
        invoiceNr: null,
        transactionId: null,
        laterFiscalization: null,

        paymentMethods: [],
        cashiers: [],
        salesLocations: [],

        selected_cashiers: [],
        selected_sales_locations: [],
        selected_payment_methods: [],
        interval1: null,

        dataTableSettings: {
            id: 'dtInvoices',
            title: 'List of invoices',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true },
                { text: "Invoice nr.", value: "invoice_nr", class: "text-xs-left", visibility: true },
                { text: "Sales location", value: "sales_location_nr", class: "text-xs-left", visibility: true },
                { text: "Cashier", value: "cashier_nr", class: "text-xs-left", visibility: true },
                { text: "Payment method", value: "payment_method_name", class: "text-xs-left", visibility: true },
                { text: "Amount", value: "amount", class: "text-xs-left", visibility: true },
                { text: "ZKI", value: "zoi", class: "text-xs-left", visibility: true },
                { text: "JIR", value: "eor", class: "text-xs-left", visibility: true },
                { text: "Transaction ID", value: "transaction_id", class: "text-xs-left", visibility: true },
                { text: "Timestamp", value: "date_time", align: "right", class: "text-xs-right", visibility: true },
                { text: "Actions", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
            ],
            updateInterval: true,
            rowsPerPage: 10,
            //endpoint: 'ina-eolgroup/invoices',
            endpoint: 'v1/invoices',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_invoices',
                totalItems : {
                    event: ''
                },
                search: 'dt-invoices-search',
                toggleColumnsId: 'toggle-invoices-list-columns'
            }


        }
    }),

    watch: {
        restriction_start_date (val) {
            window.console.log(val)
            if(isNaN(Date.parse(val)) == false) {
                this.restriction_start_year = new Date(val).getFullYear()
            }

            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            if(isNaN(Date.parse(val)) == false) {
                this.restriction_end_year = new Date(val).getFullYear()
            }

            this.restriction_end_date_formatted = formatDate(val)
        },

        selected_payment_methods(val) {
            window.console.log(val)
        },

        card1: {
            handler(value) {
                //window.console.log("Telemetry card1: " + value)
                this.$store.commit("APP_SETTING", {
                    setting: "invoices",
                    key: "filter_results_open",
                    value: value
                })
            },

            //immediate: true
        }
    },

    computed: {
         columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {
                startYear: this.restriction_start_year,
                endYear: this.restriction_end_year,
                startDate: this.restriction_start_date,
                endDate: this.restriction_end_date,
                startTime: this.start_time,
                endTime: this.end_time,
                zoi: this.zoi,
                eor: this.eor,
                invoiceNr: this.invoiceNr,
                transactionId: this.transactionId,
                selectedPaymentMethods: this.selected_payment_methods.map(item => item.type),
                selectedSalesLocations: this.selected_sales_locations.map(item => item.sales_location_nr),
                selectedCashiers: this.selected_cashiers.map(item => item.cashier_nr),
                laterFiscalization: this.laterFiscalization
            }
        },

        laterFiscalizationOnlyLabel() {
            if(this.laterFiscalization == true) {
                return 'Yes'
            }

            return "No"
        }
    },

    methods: {
         startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        search() {
            EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            EventBus.$emit('widgets', this.queryParameters)
        },

        clearZki() {
            this.zoi = null;
        },

        clearJir() {
            this.eor = null
        },

        clearInvoiceNumber() {
            this.invoiceNr = null
        },

        clearTransactionId() {
            this.transactionId = null
        },

        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
                this.salesLocations = response.data.businessPremises
                this.cashiers = response.data.cashiers
                this.paymentMethods = response.data.paymentTypes
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },

        exportData() {
            window.console.log("exporting data ...");
            let parameters = {
                startYear: this.restriction_start_year,
                endYear: this.restriction_end_year,
                startDate: this.restriction_start_date,
                endDate: this.restriction_end_date,
                startTime: this.start_time,
                endTime: this.end_time,
                zoi: this.zoi,
                eor: this.eor,
                invoiceNr: this.invoiceNr,
                transactionId: this.transactionId,
                selectedPaymentMethods: this.selected_payment_methods.map(item => item.type),
                selectedSalesLocations: this.selected_sales_locations.map(item => item.sales_location_nr),
                selectedCashiers: this.selected_cashiers.map(item => item.cashier_nr),
                laterFiscalization: this.laterFiscalization
            }
            
            addExportToQueue('invoices', parameters);
        },

        displayResults() {
            let vm = this
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
                EventBus.$emit('widgets', vm.queryParameters)
            }, 280)
        }
    },

    created() {
        var vm = this
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 

        vm.card1 = vm.$store.getters.appSettings.invoices.filter_results_open

        this.restriction_start_date = getCurrentDate()
        //this.restriction_start_date = "2022-01-01";
        this.restriction_end_date = getCurrentDate()
        //this.restriction_start_date = "2022-09-25";
        //this.restriction_end_date = "2022-09-25";
        this.start_time = "00:00"
        //this.endTime = getCurrentTime()
        this.end_time = "23:59";


        let ipp = getAppSettingsFromLocalStorage({
            setting: "dt_invoices",
            key: "items_per_page"
        })

        if(ipp !== null) {
            this.dataTableSettings.rowsPerPage = ipp
        }

        /*if(isMobileDevice(this)) {
            this.card1 = false
        }*/

        //this.paymentMethods.push({id: 1, name: 'CREDIT CARD'});
        //this.paymentMethods.push({id: 2, name: 'FREE VEND'});
        //this.paymentMethods.push({id: 3, name: 'CASH'});

        //window.console.log(getCurrentDate());

        this.setParametersData();

        this.interval1 = setInterval(() => {
            vm.setParametersData()
        }, 160000)


    },

    mounted() {
        setTimeout(() => {
            EventBus.$emit('widgets', this.queryParameters)
        }, 400)
    },

    beforeDestroy() {
        EventBus.$off(this.dataTableSettings.id)
        EventBus.$off(this.dataTableSettings.eventBus.refreshDataTable)
        EventBus.$off(this.dataTableSettings.eventBus.search)

        this.$store.commit('SET_SEARCH', '');
        this.$store.commit('SET_SEARCH_FIELD_STATE', false);
        this.$store.commit('SET_MOBILE_SEARCH', false)  

        clearInterval(this.interval1)
    },

    destroyed() {

    }
}

</script>